import { Transition } from "@headlessui/react";
import { useCallback, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { useOnClickOutside } from "usehooks-ts";
import { Text } from "../../../designSystemLegacy/typography/Text";
import { useFetchLiveBroadcasts } from "../../../api/data/broadcasts/useFetchLiveBroadcasts";
import { useAlert } from "../../../providers/AlertProvider/hooks/useAlert";
import { useGetNavbarBroadcastSeries } from "../Navbar/hooks/useGetNavbarBroadcastSeries";
import { ExternalArrow } from "./assets/ExternalArrow";
import { DrawerItemGroup } from "./components/DrawerItemGroup";
import {
  DrawerItemsWrapper,
  DrawerLogo,
  StyledAnchor,
  StyledLink,
  StyledLogoutButton,
  StyledNavbarLogoItem,
} from "./styled";
import { NavbarItem } from "../../../api/data/sanity/getSettings/schemas/NavbarSchema";
import { getLinkUrl } from "./getLinkUrl";

export interface ExpandableNavbarItem extends NavbarItem {
  isExpanded?: boolean;
}

interface MobileNavDrawerProps {
  isExpanded: boolean;
  navItems: ExpandableNavbarItem[];
  navbarRef?: React.RefObject<HTMLDivElement>;
  isSignedIn: boolean;
  onLogout?: () => void;
  onClose?: (event: TouchEvent | null) => void;
  isAutomaticLiveEnabled?: boolean;
}

const MobileNavDrawer = ({
  isExpanded,
  navItems,
  navbarRef,
  isSignedIn,
  onLogout,
  onClose,
  isAutomaticLiveEnabled,
}: MobileNavDrawerProps) => {
  const location = useLocation();
  const node = useRef<HTMLDivElement | null>(null);
  useOnClickOutside(node, (e) => {
    if (e.target && !navbarRef?.current?.contains(e.target as Node)) {
      onClose?.(null);
    }
  });

  const { showInfoAlert } = useAlert();

  const [expandedItem, setExpandedItem] = useState<ExpandableNavbarItem | null>(null);

  const onLiveBroadcastStart = useCallback(() => {
    showInfoAlert("Live stream just started!");
  }, [showInfoAlert]);
  const { data: liveBroadcasts } = useFetchLiveBroadcasts({
    onLiveBroadcastStart,
    enabled: isAutomaticLiveEnabled,
  });

  const broadcastSeries = useGetNavbarBroadcastSeries({ liveBroadcasts });

  const navbarLiveChildren: ExpandableNavbarItem[] = useMemo(() => {
    if (!broadcastSeries || !isAutomaticLiveEnabled) return [];

    return Object.values(broadcastSeries).map((broadcast) => ({
      _type: "navbarItem",
      _key: broadcast.id,
      title: broadcast.title,
      route: `/live/${broadcast.slug}`,
    }));
  }, [broadcastSeries, isAutomaticLiveEnabled]);

  const pathname = useLocation().pathname;
  const itemsToUse: ExpandableNavbarItem[] = useMemo(() => {
    let items = navItems;
    if (navbarLiveChildren.length > 0) {
      // Insert live at top of list
      items = [
        {
          _type: "navbarItem",
          _key: "live",
          title: "Live",
          children: navbarLiveChildren,
        },
        ...items,
      ];
    }
    return items;
  }, [navItems, navbarLiveChildren]);

  const collapseAndClose = () => {
    setExpandedItem(null);
    if (onClose) {
      onClose(null);
    }
  };

  const onGroupClicked = (clickedItem: ExpandableNavbarItem) => {
    if (expandedItem?._key === clickedItem._key) {
      setExpandedItem(null);
    } else {
      setExpandedItem(clickedItem);
    }
  };

  const activeItem = useMemo(() => {
    let matchingItem: ExpandableNavbarItem | undefined = undefined;
    for (const item of itemsToUse) {
      if (item.children) {
        const matchingChild = item.children.find((child) => child.route === pathname);
        if (matchingChild) {
          matchingItem = matchingChild;
          break;
        }
      } else if (item.route === pathname) {
        matchingItem = item;
        break;
      }
    }
    return matchingItem;
  }, [itemsToUse, pathname]);
  return (
    <Transition
      show={isExpanded}
      enter="transition-all duration-[600ms]"
      enterFrom="-translate-x-full "
      enterTo="translate-y-0 "
      leave="transition-all duration-[600ms]"
      leaveFrom="translate-y-0 "
      leaveTo="-translate-x-full "
      ref={node}
      as="div"
      className="fixed left-0 z-drawer flex h-full w-72 select-none flex-col gap-8 bg-canvas-100 pl-[15px]"
    >
      <DrawerItemsWrapper>
        <StyledNavbarLogoItem isActive={location.pathname === "/"} onClick={onClose ? () => onClose(null) : undefined}>
          <DrawerLogo isActive={location.pathname === "/"} />
        </StyledNavbarLogoItem>
        {itemsToUse.map((item) =>
          item.children && item.children.length > 0 ? (
            <DrawerItemGroup
              key={item.title}
              item={item}
              activeItem={activeItem}
              expandedItem={expandedItem}
              onGroupClick={onGroupClicked}
              onNavigate={collapseAndClose}
            />
          ) : item.hasExternalIcon ? (
            <StyledAnchor key={item.title} href={item.externalLink} target="_blank" $isActive={false}>
              <Text typography="mobileE1Eyebrow">{item.title}</Text>
              <ExternalArrow />
            </StyledAnchor>
          ) : (
            <StyledLink
              key={item.title}
              $isActive={item.route === location.pathname}
              onClick={() => collapseAndClose()}
              to={getLinkUrl(item)}
              reloadDocument={item.externalLink !== undefined}
            >
              <Text typography="mobileE1Eyebrow">{item.title}</Text>
            </StyledLink>
          ),
        )}
        {isSignedIn && (
          <StyledLogoutButton variant="tertiary" onClick={() => (onLogout ? onLogout() : undefined)}>
            <Text typography="mobileE1Eyebrow">Sign out</Text>
          </StyledLogoutButton>
        )}
      </DrawerItemsWrapper>
    </Transition>
  );
};

export { MobileNavDrawer };
