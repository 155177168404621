import { useCallback, useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { RandomInfoModal } from "../../../../RandomInfoModal";

const MAX_CLICKS = 8;

const pathsToSendToHomepage = ["/", "/privacy-policy"];

function NavbarLogoItem({
  children,
  className,
  onClick,
}: {
  children?: React.ReactNode;
  className?: string;
  onClick?: () => void;
}) {
  const [clickCount, setClickCount] = useState(0);

  const onLogoClicked = useCallback(() => {
    setClickCount((clickCount) => (clickCount >= MAX_CLICKS ? 0 : clickCount + 1));
  }, []);

  const path = useLocation().pathname;

  const toRedirect = useMemo(() => {
    if (path.startsWith("/dota")) {
      return "/dota";
    }

    if (pathsToSendToHomepage.includes(path)) {
      return "/";
    }

    return "/cs";
  }, [path]);

  return (
    <div>
      <Link to={toRedirect} className={className} title="Go to homepage" onClick={onClick ? onClick : onLogoClicked}>
        {children}
      </Link>
      {clickCount >= MAX_CLICKS && <RandomInfoModal onClose={() => setClickCount(0)} />}
    </div>
  );
}

export { NavbarLogoItem };
