import { forwardRef, PropsWithChildren, Ref } from "react";
import { Container, Label, StyledInput } from "./styled";

interface CheckboxProps {
  id?: string;
  name?: string;
  checked?: boolean;
  required?: boolean;
  className?: string;
  onChange?: (checked: boolean) => void;
}

const Checkbox = forwardRef(
  (
    { id, name, checked, required, className, children, onChange }: PropsWithChildren<CheckboxProps>,
    ref: Ref<HTMLInputElement>,
  ) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(event.target.checked);
      }
    };

    return (
      <Container className={className}>
        <StyledInput
          ref={ref}
          type="checkbox"
          id={id}
          name={name}
          required={required}
          checked={checked}
          onChange={handleChange}
        />
        <Label htmlFor={id}>{children}</Label>
      </Container>
    );
  },
);

Checkbox.displayName = "Checkbox";

export { Checkbox };
