/* eslint-disable react-refresh/only-export-components */
import classNames from "classnames";
import { Link } from "react-router-dom";
import { GameIcon } from "@shared/components/GameIcon";
import { GameId } from "@providers/GameIdProvider";

export const GameNavItem = ({
  gameId,
  selected = false,
  className,
}: {
  gameId: GameId;
  selected?: boolean;
  className?: string;
}) => {
  return (
    <Link
      to={gameId}
      className={classNames("my-2 flex items-center rounded border px-3 ", className, {
        "border-transparent hover:border-neutral-50": !selected,
        "border-yellow": selected,
      })}
    >
      <GameIcon gameId={gameId} />
    </Link>
  );
};
