import { useQuery } from "@tanstack/react-query";
import { useCallback } from "react";
import { BroadcastMessage } from "../../ApiProvider/DDSClient";
import { getLiveBroadcasts } from "./getLiveBroadcasts";
import { useDDSEvents } from "../../../shared/hooks/useDDSEvents";

/**
 * Fetches live broadcasts and subscribes to DDS events in order to refetch the data when a broadcast goes live or offline.
 */
interface UseFetchLiveBroadcastsProps {
  onLiveBroadcastStart?: () => void;
  enabled?: boolean;
}
const useFetchLiveBroadcasts = (options?: UseFetchLiveBroadcastsProps) => {
  const { data, refetch, isLoading } = useQuery({
    queryKey: ["liveBroadcasts"],
    queryFn: getLiveBroadcasts,
    enabled: options?.enabled === undefined ? true : options.enabled,
  });

  const { onLiveBroadcastStart } = options || {};
  // listener for the DDS events that indicate changes in live broadcasts
  const broadcastsListener = useCallback(
    (broadcastMessage: BroadcastMessage) => {
      switch (broadcastMessage.action) {
        case "went-live":
          refetch();
          onLiveBroadcastStart?.();
          break;
        case "went-offline":
          refetch();
          break;
        default:
          break;
      }
    },
    [onLiveBroadcastStart, refetch],
  );
  useDDSEvents({
    broadcastsListener,
    disabled: isLoading,
  });

  return {
    data,
    isLoading,
  };
};

export { useFetchLiveBroadcasts };
