import { z } from "zod";

const BaseNavbarItemSchema = z.object({
  _type: z.literal("navbarItem"),
  _key: z.string(),
  title: z.string(),
  route: z.string().optional().nullable(),
  externalLink: z.string().optional(),
  hasExternalIcon: z.boolean().optional(),
});

const NavbarItemSchema: z.ZodType<NavbarItem> = BaseNavbarItemSchema.extend({
  children: z.lazy(() => NavbarItemSchema.array().optional().nullable()),
});

export const NavbarSchema = z.object({
  _type: z.literal("navbar"),
  items: z.array(NavbarItemSchema),
});

export type NavbarItem = z.infer<typeof BaseNavbarItemSchema> & {
  children?: NavbarItem[] | null;
};
