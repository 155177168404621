import { Transition } from "@headlessui/react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { useOnClickOutside } from "usehooks-ts";
import { ExternalArrow } from "./assets/ExternalArrow";
import { DrawerItemGroup } from "./components/DrawerItemGroup";
import { DrawerItemsWrapper, DrawerLogo, StyledAnchor, StyledLink, StyledNavbarLogoItem } from "./styled";
import { getLinkUrl } from "./getLinkUrl";
import { useAlert } from "../../../../../../../providers/AlertProvider/hooks/useAlert";
import { NavbarItem } from "../../../../../../../api/data/sanity/getSettings/schemas/NavbarSchema";
import { useFetchLiveBroadcasts } from "../../../../../../../api/data/broadcasts/useFetchLiveBroadcasts";
import { useGetNavbarBroadcastSeries } from "../../../../hooks/useGetNavbarBroadcastSeries";
import { Text } from "../../../../../../../designSystemLegacy/typography/Text";
import { MobileGameSelection } from "./components/MobileGameSelection";
import { GameId } from "@providers/GameIdProvider";
import { isSsrPage } from "../../../../../../../../ssrPagesRoutes/isSsrPage";
import { environment } from "../../../../../../../config";
import { useGameId } from "@providers/GameIdProvider/useGameId";
import { DotaSearchButton } from "../../../DotaSearchButton";
import { useNavBarContext } from "@shared/components/Navbar/useNavBarContext";

export interface ExpandableNavbarItem extends NavbarItem {
  isExpanded?: boolean;
}

const MobileNavDrawer = ({
  isExpanded,
  nav,
  navbarRef,
  isSignedIn,
  onLogout,
  onClose,
  isAutomaticLiveEnabled,
}: {
  isExpanded: boolean;

  nav?: { [K in GameId]: ExpandableNavbarItem[] };
  navbarRef?: React.RefObject<HTMLDivElement>;
  isSignedIn: boolean;
  onLogout?: () => void;
  onClose?: (event: TouchEvent | null) => void;
  isAutomaticLiveEnabled?: boolean;
}) => {
  const { gameId } = useGameId();

  const location = useLocation();
  const node = useRef<HTMLDivElement | null>(null);
  useOnClickOutside(node, (e) => {
    if (e.target && !navbarRef?.current?.contains(e.target as Node)) {
      onClose?.(null);
    }
  });

  const { showInfoAlert } = useAlert();
  const [expandedItem, setExpandedItem] = useState<ExpandableNavbarItem | null>(null);
  const { showDotaSearchButton } = useNavBarContext();

  const onLiveBroadcastStart = useCallback(() => {
    showInfoAlert("Live stream just started!");
  }, [showInfoAlert]);
  const { data: liveBroadcasts } = useFetchLiveBroadcasts({
    onLiveBroadcastStart,
    enabled: isAutomaticLiveEnabled,
  });

  const broadcastSeries = useGetNavbarBroadcastSeries({ liveBroadcasts });

  const navbarLiveChildren: ExpandableNavbarItem[] = useMemo(() => {
    if (!broadcastSeries || !isAutomaticLiveEnabled) return [];

    return Object.values(broadcastSeries).map((broadcast) => ({
      _type: "navbarItem",
      _key: broadcast.id,
      title: broadcast.title,
      route: `/live/${broadcast.slug}`,
    }));
  }, [broadcastSeries, isAutomaticLiveEnabled]);

  const { pathname } = useLocation();

  const onGroupClicked = (clickedItem: ExpandableNavbarItem) => {
    if (expandedItem?._key === clickedItem._key) {
      setExpandedItem(null);
    } else {
      setExpandedItem(clickedItem);
    }
  };

  const itemsForCurrentGame = useMemo(() => (nav && gameId ? nav[gameId] : []), [gameId, nav]);

  const itemsWithLiveLink = useMemo(() => {
    let items = itemsForCurrentGame;

    // no live link for RL or global context
    if (navbarLiveChildren.length > 0 && gameId === "cs") {
      // Insert live at top of list
      items = [
        {
          _type: "navbarItem",
          _key: "live",
          title: "Live",
          children: navbarLiveChildren,
        },
        ...items,
      ];
    }

    // Add a homepage button when a game is selected
    if (gameId !== null) {
      items = [
        {
          _type: "navbarItem",
          _key: "homepage",
          title: "Home",
          route: `/${gameId}`,
        },
        ...items,
      ];
    }
    return items;
  }, [itemsForCurrentGame, navbarLiveChildren, gameId]);

  const activeItem = useMemo(() => {
    let matchingItem: ExpandableNavbarItem | undefined = undefined;
    for (const item of itemsWithLiveLink) {
      if (item.children) {
        const matchingChild = item.children.find((child) => child.route === pathname);
        if (matchingChild) {
          matchingItem = matchingChild;
          break;
        }
      } else if (item.route === pathname) {
        matchingItem = item;
        break;
      }
    }
    return matchingItem;
  }, [itemsWithLiveLink, pathname]);

  useEffect(() => {
    if (isExpanded) {
      setExpandedItem(null);
      if (onClose) {
        onClose(null);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <Transition
      show={isExpanded}
      enter="transition-all duration-[600ms]"
      enterFrom="-translate-x-full "
      enterTo="translate-y-0 "
      leave="transition-all duration-[600ms]"
      leaveFrom="translate-y-0 "
      leaveTo="-translate-x-full "
      ref={node}
      as="div"
      className="fixed left-0 z-drawer flex h-full w-72 select-none flex-col items-start gap-8 bg-canvas-100 pl-[15px]"
    >
      <DrawerItemsWrapper>
        <StyledNavbarLogoItem isActive={location.pathname === "/"}>
          <DrawerLogo isActive={location.pathname === "/"} />
        </StyledNavbarLogoItem>
        <MobileGameSelection />
        {itemsWithLiveLink.map((item) => {
          if (item?.children?.length) {
            return (
              <DrawerItemGroup
                key={item.title}
                item={item}
                activeItem={activeItem}
                expandedItem={expandedItem}
                onGroupClick={onGroupClicked}
              />
            );
          }

          if (item.hasExternalIcon) {
            return (
              <StyledAnchor key={item.title} href={item.externalLink} target="_blank" $isActive={false}>
                <Text typography="mobileE1Eyebrow">{item.title}</Text>
                <ExternalArrow />
              </StyledAnchor>
            );
          }

          return (
            <StyledLink
              key={item.title}
              $isActive={item.route === location.pathname}
              to={getLinkUrl(item)}
              reloadDocument={isSsrPage({ pathname: getLinkUrl(item), environment }) || item.externalLink !== undefined}
            >
              <Text typography="mobileE1Eyebrow">{item.title}</Text>
            </StyledLink>
          );
        })}
        {showDotaSearchButton && <DotaSearchButton className="" />}
        {isSignedIn && (
          <button
            onClick={() => (onLogout ? onLogout() : undefined)}
            className="mt-auto w-fit pt-4 text-neutral-50 font-style-navigation-mobile"
          >
            Sign out
          </button>
        )}
      </DrawerItemsWrapper>
    </Transition>
  );
};

export { MobileNavDrawer };
