import cookies from "js-cookie";
import { LoginResult, RegisterResult, REGISTRATION_EMAIL_COOKIE } from "../..";
import { LoginRequest, RequestResetPasswordRequest, SignupRequest } from "../../../../api/auth/types";
import { AuthApiClient } from "../../../../api/auth/AuthApiClient";
import { shouldRefreshToken, updateTokens } from "../../../authInterceptor";
import { apiBaseURL } from "../../../../config";
import { CheckPasswordRequest } from "../../../../api/auth/AuthApiClient/types";

interface AccessTokenJson {
  exp: string;
  iss: string;
  username?: string;
}

interface IdTokenJson {
  email?: string;
}

class AuthService {
  constructor(private readonly authApi: AuthApiClient) {}
  get userId() {
    return this.accessTokenJson?.username ?? this.accessTokenJson?.iss;
  }

  get email(): string | undefined {
    return this.idTokenJson?.email;
  }

  get accessTokenExpiresAt() {
    return this.accessTokenJson?.exp;
  }

  public async login(payload: LoginRequest): Promise<LoginResult> {
    if (this.hasAccessToken) {
      return "success";
    }

    return this.authApi.login(payload);
  }

  public logout = () => this.authApi.logout();

  public async register(payload: SignupRequest): Promise<RegisterResult> {
    if (this.hasAccessToken) {
      return "success";
    }

    return this.authApi.signup(payload);
  }

  get isAuthenticated() {
    return this.hasAccessToken;
  }

  public resetPasswordRequest = (payload: RequestResetPasswordRequest) => this.authApi.requestResetPassword(payload);

  public resendVerificationEmail = () => {
    const email = cookies.get(REGISTRATION_EMAIL_COOKIE);
    if (!email) {
      throw new Error("No email found in cookies");
    }
    return this.authApi.resendVerificationEmail({ email });
  };

  public async checkPassword(payload: CheckPasswordRequest): Promise<boolean> {
    return this.authApi.checkPassword(payload);
  }

  public async deleteProfile(userId: string): Promise<void> {
    return this.authApi.deleteUser(userId);
  }

  public updateSession() {
    return updateTokens(`${apiBaseURL}/v1/auth/token`);
  }

  get hasAccessToken() {
    return !!this.accessTokenFromCookie;
  }

  get shouldUpdateAccessToken() {
    return shouldRefreshToken(this.accessTokenFromCookie, this.refreshTokenFromCookie, this.idTokenFromCookie);
  }

  private get idTokenJson(): IdTokenJson | undefined {
    const idToken = this.idTokenFromCookie;
    if (idToken === undefined) {
      return undefined;
    }

    const payload = JSON.parse(window.atob(decodeURIComponent(idToken.split(".")[1])));
    return payload as IdTokenJson;
  }

  private get accessTokenJson(): AccessTokenJson | undefined {
    const accessToken = this.accessTokenFromCookie;
    if (accessToken === undefined) {
      return undefined;
    }

    const payload = JSON.parse(window.atob(decodeURIComponent(accessToken.split(".")[1])));
    return payload;
  }

  private get accessTokenFromCookie() {
    return cookies.get("access_token");
  }

  private get idTokenFromCookie() {
    return cookies.get("id_token");
  }

  private get refreshTokenFromCookie() {
    return cookies.get("refresh_token");
  }
}

export { AuthService };
