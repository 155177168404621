import { ReactNode, createContext, useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { MetaTagsInfo } from "../../api/data/sanity/types/MetaTagsInfo";
import { useFetchGhostPage } from "../AddGhostPage/hooks/useFetchGhostPage";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { urlFor } from "../../api/apiClients/sanity";

interface MetaContextContent {
  route: string;
  title: string;
  notFound?: boolean;
  metaTagsInfo?: MetaTagsInfo;
}
export interface MetaContextType {
  metaContext: MetaContextContent | null;
  updateMetaTags: (title: string, notFound?: boolean, metaTagsInfo?: MetaTagsInfo) => void;
}

// Create a context for the meta information
export const MetaContext = createContext<MetaContextType | undefined>(undefined);
const defaultDescription =
  "Discover all things CS, Dota, schedules, games and more. Transform your viewing experience, stay updated, and earn rewards through our mini-games daily.";
const defaultTitle = "BLAST.tv - The home of the best CS and Dota tournaments, news and esports community!";

export function MetaProvider({ children }: { children: ReactNode }) {
  const [metaContextContent, setMetaContextContent] = useState<MetaContextContent | null>(null);
  const location = useLocation();
  const { data } = useFetchGhostPage({ route: location.pathname });

  const updateMetaTags = useCallback(
    (title: string, notFound?: boolean, metaTagsInfo?: MetaTagsInfo) => {
      if (title === document.title) return;
      setMetaContextContent({
        route: location.pathname,
        title,
        notFound,
        metaTagsInfo,
      });
    },
    [location.pathname],
  );

  useEffect(() => {
    if (metaContextContent?.route === location.pathname) return;
    if (metaContextContent?.title === defaultTitle) return;
    updateMetaTags(defaultTitle, false);
  }, [location.pathname, metaContextContent, updateMetaTags]);

  // Create a meta object to be passed as value to the context
  const meta = {
    metaContext: metaContextContent,
    updateMetaTags,
  };

  const image = data?.metaTagsInfo.metaImage || metaContextContent?.metaTagsInfo?.metaImage;
  const description =
    data?.metaTagsInfo.metaDescription || metaContextContent?.metaTagsInfo?.metaDescription || defaultDescription;
  const title = data?.metaTagsInfo.metaTitle || metaContextContent?.metaTagsInfo?.metaTitle || defaultTitle;
  const canonicalUrl = `https://blast.tv${
    data?.metaTagsInfo.canonicalUrl ||
    metaContextContent?.metaTagsInfo?.canonicalUrl ||
    location.pathname?.toLowerCase()
  }`;

  useEffect(() => {
    if (title.toLowerCase() !== document.title.trim().toLowerCase()) {
      document.title = title;
    }
  }, [title]);

  return (
    <MetaContext.Provider value={meta}>
      <Helmet>
        <link rel="canonical" href={canonicalUrl} />
        <meta name="description" content={description} />

        {/* Open Graph */}
        <meta property="og:url" content={`https://blast.tv${location.pathname?.toLowerCase()}`} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        {image ? <meta property="og:image" content={urlFor(image).url()} /> : null}
      </Helmet>
      <HelmetProvider>{children}</HelmetProvider>
    </MetaContext.Provider>
  );
}
