import { blastApi } from "../../../apiClients/blastApi";

export interface Broadcast {
  id: string;
  title: string;
  description?: string;
  slug: string;
  live: boolean;
  priority: number;
  chatId: string;
  videoId: string;
  videoSrc: string;
  videoAlternativeSrc?: string;
  videoOffset: number;
  videoStartTime: string;
  createdAt: string;
  seriesIds?: Array<string>;
}

export const getLiveBroadcasts = async () => {
  const { data } = await blastApi.get<Array<Broadcast>>(`/v1/broadcasts/live`);
  return data;
};
