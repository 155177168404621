import { z } from "zod";

export const MapSchema = z.object({
  uuid: z.string(),
  name: z.string().nullable().optional(),
  actualStartTime: z.string().nullable().optional(),
  scheduledStartTime: z.string(),
  matchEndedTime: z.string().nullable().optional(),
  teamAScore: z.number(),
  teamBScore: z.number(),
});

export type Map = z.infer<typeof MapSchema>;
