import { GameIdSchema } from "@data/tournament/shared/types/v2/GameIdSchema";
import { z } from "zod";

export const gender = z.enum(["MALE", "FEMALE", "NON_BINARY", "PREFER_NOT_TO_SAY"]);

export const userProfile = z.object({
  id: z.string(),
  username: z.string(),
  email: z.string().email(),
  createdAt: z.coerce.date(),
  birthDate: z.coerce.date(),
  gender: gender,
  country: z.string(),
  avatarId: z.string(),
  optIn: z.boolean(),
  chatRole: z.enum(["admin", "moderator", "verified"]).optional(),
  favoriteTeams: z.string().array().optional(),
  avatars: z.string().array().optional(),
  emojis: z.string().array().optional(),
  steamId: z.string().nullish(),
  steamUsername: z.string().nullish(),
  highContrastMode: z.boolean().optional(),
  chatRulesAcceptedAt: z.string().datetime().optional(),
  vipStatusActiveUntil: z.string().datetime().optional(),
  preferredGames: z.array(GameIdSchema).optional(),
});

export const patchUserProfileRequest = userProfile
  .pick({
    birthDate: true,
    gender: true,
    country: true,
    optIn: true,
    favoriteTeams: true,
    avatarId: true,
    highContrastMode: true,
    preferredGames: true,
    steamId: true,
  })
  .partial();

export const postUserProfileRequest = userProfile
  .pick({
    avatarId: true,
    birthDate: true,
    country: true,
    gender: true,
    id: true,
    username: true,
    favoriteTeams: true,
    preferredGames: true,
  })
  .required();

export const validateUsernameResponse = z.object({
  code: z.enum(["success", "username-taken", "username-not-allowed"]),
});

export const GetSteamConnectionLinkResponseSchema = z.object({
  url: z.string(),
});
