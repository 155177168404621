import { ReactNode } from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { colors } from "../../../../../designSystemLegacy/colors";
import { NavbarItem } from "../../../../../api/data/sanity/getSettings/schemas/NavbarSchema";
import { isSsrPage } from "../../../../../../ssrPagesRoutes/isSsrPage";
import { environment } from "../../../../../config";

const anchorReset = css`
  color: ${colors.white};
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
`;

const StyledAnchor = styled.a`
  ${anchorReset}
`;

const StyledLink = styled(Link)`
  ${anchorReset}
`;

function getLinkUrl(navbarItem: NavbarItem): string {
  if (navbarItem.externalLink) {
    return navbarItem.externalLink.startsWith("/")
      ? navbarItem.externalLink
      : new URL(navbarItem.externalLink).pathname;
  } else {
    return navbarItem.route ?? "#";
  }
}

const NavLink = ({
  children,
  navbarItem,
  className,
  onClick,
}: {
  children: ReactNode;
  navbarItem: NavbarItem;
  className?: string;
  onClick?: () => void;
}) => {
  return navbarItem.hasExternalIcon ? (
    <StyledAnchor className={className} href={navbarItem.externalLink} target="_blank" rel="noreferrer">
      {children}
    </StyledAnchor>
  ) : (
    <StyledLink
      className={className}
      onClick={onClick}
      to={getLinkUrl(navbarItem)}
      reloadDocument={
        isSsrPage({ pathname: getLinkUrl(navbarItem), environment }) || navbarItem.externalLink !== undefined
      }
    >
      {children}
    </StyledLink>
  );
};

export { NavLink };
