import { useState } from "react";
import { Location, useLocation } from "react-router-dom";
import { Icon } from "../../../Icon";
import { NavLink } from "../NavLink";
import { Container, DropdownContent, DropdownItem, MenuTitleWrapper } from "./styled";
import { NavbarItem as NavbarItemType } from "../../../../../api/data/sanity/getSettings/schemas/NavbarSchema";

interface NavbarItemProps {
  item: NavbarItemType;
  isActive?: boolean;
  isLiveOption?: boolean;
}

const isItemActive = (item: NavbarItemType, location: Location) => {
  const isRouteActive = (route: string | undefined | null): boolean => {
    return !!route && route === location.pathname;
  };

  if (item.children) {
    return item.children.some((child) => isRouteActive(child.route));
  } else {
    return isRouteActive(item.route);
  }
};

const NavbarItem = ({ item, isActive, isLiveOption }: NavbarItemProps) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const location = useLocation();

  return (
    <Container
      onMouseEnter={() => setDropdownOpen((prev) => !prev)}
      onMouseLeave={() => setDropdownOpen((prev) => !prev)}
      isActive={isActive}
    >
      {item.children ? (
        <>
          <MenuTitleWrapper isDropdownParent={!!item.children?.length}>
            {isLiveOption ? <div className="mr-2 size-2 rounded-full bg-red" /> : null}

            <span>{item.title}</span>
            <Icon icon="arrowDownNavbar" className="text-neutral-20" />
          </MenuTitleWrapper>
          {dropdownOpen && (
            <DropdownContent>
              {item.children.map((child) => (
                <DropdownItem isActive={isItemActive(child, location)} key={child.title} navbarItem={child}>
                  {child.title}
                </DropdownItem>
              ))}
            </DropdownContent>
          )}
        </>
      ) : (
        <MenuTitleWrapper isDropdownParent={false}>
          <NavLink navbarItem={item}>{item.title}</NavLink>
          {item.hasExternalIcon && <Icon icon="arrowExternalLinkNavbar" />}
        </MenuTitleWrapper>
      )}
    </Container>
  );
};

export { NavbarItem };
