import { ReactNode, createContext, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { useBreakpoints } from "@shared/helpers/useBreakpoints";
import { isSignupPage } from "../../hooks/useSignupCheck";
import { NavBarDesktop } from "./components/NavBarDesktop";
import { NavBarMobile } from "./components/NavBarMobile";
import { useGetSettings } from "../../../api/data/sanity/getSettings/useGetSettings";
import { useGameId } from "@providers/GameIdProvider/useGameId";

const NavbarComponent = () => {
  const location = useLocation();
  const hideNavBar = isSignupPage(location.pathname);
  const isEmbedPage = location.pathname.includes("/embed");
  const { isSmallDesktop } = useBreakpoints();

  const { data } = useGetSettings();

  if (hideNavBar || isEmbedPage) {
    return null;
  }

  return isSmallDesktop ? (
    <NavBarDesktop nav={data?.nav} isAutomaticLiveEnabled={data?.settings?.enableAutomaticLiveState} />
  ) : (
    <NavBarMobile nav={data?.nav} isAutomaticLiveEnabled={data?.settings?.enableAutomaticLiveState} />
  );
  null;
};

function useData() {
  const [isNavTransparent, setIsNavTransparent] = useState(false);
  const { gameId } = useGameId();
  const showDotaSearchButton = useMemo(() => {
    if (gameId !== "dota") return false;

    return true;
  }, [gameId]);

  return {
    isNavTransparent,
    setIsNavTransparent,
    showDotaSearchButton,
  };
}

export const NavBarContext = createContext<ReturnType<typeof useData> | undefined>(undefined);

export const NavBarProvider = ({ children }: { children: ReactNode }) => {
  const data = useData();

  return (
    <NavBarContext.Provider value={data}>
      <NavbarComponent />
      {children}
    </NavBarContext.Provider>
  );
};
