import styled from "styled-components";
import { colors } from "../../../designSystemLegacy/colors";

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  width: fit-content;
  cursor: pointer;
`;

export const StyledInput = styled.input`
  flex-shrink: 0;
  -webkit-appearance: none;
  appearance: none;
  width: 1.2rem;
  height: 1.2rem;
  margin-right: 0.5rem;
  border-radius: 0.2rem;
  border: 1px solid ${colors.blastCanvas70}80;
  background-color: ${colors.blastCanvas};
  outline: none;
  cursor: pointer;
  transition: all 0.25s ease;

  &:checked {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid ${colors.blastCanvas50};
  }

  &:checked::before {
    font-size: 1rem;
    color: ${colors.blastCanvas};
  }

  &:focus {
    box-shadow: 0 0 0 2px ${colors.blastYellow}33;
  }
`;

export const Label = styled.label`
  padding: 0.125rem 0;
`;
