import { z } from "zod";

export const ImageSchema = z.object({
  _key: z.string().optional(),
  _type: z.literal("image"),
  asset: z.object({
    _ref: z.string(),
    _type: z.literal("reference"),
  }),
});

export type SanityImage = z.infer<typeof ImageSchema>;
