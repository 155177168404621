import { Typography } from "@blastorg/portal-pattern-library";
import { useEffect, useMemo, useRef } from "react";
import { useAuth } from "../../../../../auth/AuthProvider/hooks/useAuth";
import { useUserProfile } from "../../../../../auth/useUserProfile";
import { useAvatarModal } from "../../../../../providers/AvatarModal/useAvatarModal";
import { Avatar, AvatarContainer, Buttons, CloseIcon, Container, Info, StyledHeading } from "./styled";
import { Link } from "react-router-dom";
import { generateAssetsId } from "../../../../../api/data/assets/helpers/generateAssetsId";
import { generateImageUrl } from "../../../../../api/data/assets/helpers/generateImageUrl";

interface PopupProps {
  onClose: () => void;
}

const Popup = ({ onClose }: PopupProps) => {
  const node = useRef<HTMLDivElement | null>(null);
  const auth = useAuth();
  const userProfile = useUserProfile();
  const avatarModal = useAvatarModal();
  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      if (node.current?.contains(event.target as Node)) {
        return;
      }
      onClose();
    };

    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [onClose]);

  const avatarSrc = useMemo(() => {
    if (!userProfile?.avatarId) return undefined;
    return generateImageUrl("avatars", generateAssetsId(userProfile.avatarId, "3d"), {
      width: "150",
      format: "auto",
    });
  }, [userProfile]);

  const username = useMemo(() => {
    return userProfile?.username;
  }, [userProfile]);

  return (
    <Container ref={node}>
      <div onClick={onClose}>
        <CloseIcon icon="cross" />
      </div>
      <Info>
        <AvatarContainer>
          <Avatar src={avatarSrc} />
        </AvatarContainer>
        <StyledHeading typography="desktopH6">{username}</StyledHeading>
      </Info>
      <Buttons>
        <Link className="text-inherit no-underline" onClick={onClose} to={"/profile"}>
          <Typography variant="label3" color="inherit">
            Go to profile
          </Typography>
        </Link>
        <div onClick={() => avatarModal.setIsOpen(true)}>
          <Typography variant="label3" color="inherit">
            Change avatar
          </Typography>
        </div>
        <div onClick={auth.logout}>
          <Typography variant="label3" color="inherit">
            Sign out
          </Typography>
        </div>
      </Buttons>
    </Container>
  );
};

export { Popup };
