import styled from "styled-components";
import { colors } from "../../../../../designSystemLegacy/colors";
import { Icon } from "../../../Icon";
import { toRem } from "@shared/helpers/toRem";
import { Heading } from "../../../../../designSystemLegacy/typography/Heading";
import chatUserBg from "../../assets/chat-user-bg.png";

export const Container = styled.div`
  width: ${toRem(320)};
  position: absolute;
  top: ${toRem(48)};
  right: 0;
  z-index: 2;
  display: flex;
  flex-direction: column;
`;

export const Info = styled.div`
  width: 100%;
  background-image: url("${chatUserBg}");
  background-color: ${colors.blastPurpleLight};
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${toRem(16)};
`;

export const AvatarContainer = styled.div`
  width: ${toRem(88)};
  height: ${toRem(88)};
  background-color: ${colors.white}1a;
  border-radius: 50%;
  display: flex;
`;

export const Avatar = styled.img`
  width: 100%;
  height: 100%;
  padding: ${toRem(4)};
`;

export const StyledHeading = styled(Heading)`
  margin-top: ${toRem(12)};
  text-transform: lowercase;
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.blastPurpleLight};
  padding: 0 ${toRem(12)};

  > * {
    transition: all 0.25s ease;
    cursor: pointer;
    padding: ${toRem(12)} 0;

    :hover {
      color: ${colors.blastCanvas80};
    }
  }

  > *:not(:last-child) {
    border-bottom: 1px solid ${colors.blastCanvas20};
  }
`;

export const CloseIcon = styled(Icon)`
  cursor: pointer;
  position: absolute;
  top: ${toRem(16)};
  right: ${toRem(16)};
`;
