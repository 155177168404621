import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { colors } from "../../../../../designSystemLegacy/colors";
import { toRem } from "@shared/helpers/toRem";

export const GroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HeaderWrapper = styled.div<{
  isExpanded: boolean;
}>`
  align-items: center;
  display: flex;
  gap: ${toRem(6)};
  cursor: pointer;
  width: fit-content;
  border-bottom: 2px solid transparent;
  padding-bottom: ${toRem(8)};

  &.active {
    border-bottom: 2px solid ${colors.blastYellow};
  }

  svg {
    > path {
      fill: ${(props) => (props.isExpanded ? colors.blastYellow : colors.blastCanvas70)};
    }
  }
`;

export const OuterContentWrapper = styled.div<{
  isExpanded: boolean;
}>`
  max-height: ${(props) => (props.isExpanded ? toRem(300) : toRem(0))};
  overflow: hidden;
  transition: max-height 500ms ease-in-out;
`;

export const InnerContentWrapper = styled.div`
  width: 100%;
  margin-top: ${toRem(30)};
  display: flex;
  flex-direction: column;
  gap: ${toRem(16)};
`;

const drawerItemMixin = css`
  color: ${colors.white};
  text-decoration: none;
  display: flex;
  align-items: center;
  text-align: center;
  border-bottom: 2px solid transparent;
  transition: all 0.2s ease-in-out;
  width: fit-content;
  cursor: pointer;

  &.active {
    color: ${colors.blastYellow};
  }
`;

export const StyledLink = styled(Link)`
  ${drawerItemMixin}
  text-align: left;
`;

export const StyledAnchor = styled.a`
  ${drawerItemMixin}
  gap: ${toRem(4)};

  > svg {
    width: ${toRem(10)};
    height: ${toRem(12)};
  }
`;
