/* eslint-disable tailwindcss/no-custom-classname */
import { ExpandableNavbarItem } from "../..";
import { getLinkUrl } from "../../getLinkUrl";
import { Text } from "../../../../../designSystemLegacy/typography/Text";
import { ExternalArrow } from "../../assets/ExternalArrow";
import { GroupArrow } from "../../assets/GroupArrow";
import {
  GroupWrapper,
  HeaderWrapper,
  InnerContentWrapper,
  OuterContentWrapper,
  StyledAnchor,
  StyledLink,
} from "./styled";

interface DrawerItemGroupProps {
  item: ExpandableNavbarItem;
  activeItem?: ExpandableNavbarItem | null;
  expandedItem?: ExpandableNavbarItem | null;
  onGroupClick: (item: ExpandableNavbarItem) => void;
  onNavigate: (item: ExpandableNavbarItem) => void;
}

const DrawerItemGroup = ({ item, activeItem, onGroupClick, onNavigate, expandedItem }: DrawerItemGroupProps) => {
  const onInternalLinkClicked = (itemSelected: ExpandableNavbarItem) => {
    onNavigate(itemSelected);
  };

  return (
    <GroupWrapper>
      <HeaderWrapper
        isExpanded={expandedItem?._key === item._key}
        onClick={() => onGroupClick(item)}
        className={
          item.children?.some((child) => child?._key === activeItem?._key || child?._key === expandedItem?._key)
            ? "active"
            : ""
        }
      >
        {item.title === "Live" ? <div className="-right-10 mr-2 size-2 rounded-full bg-red" /> : null}
        <Text typography="mobileE1Eyebrow">{item.title}</Text>
        <GroupArrow />
      </HeaderWrapper>
      <OuterContentWrapper isExpanded={expandedItem?._key === item._key}>
        <InnerContentWrapper>
          {item.children?.map((child) =>
            child.hasExternalIcon ? (
              <StyledAnchor key={child.title} href={child.externalLink} target="_blank">
                <Text typography="mobileE2Eyebrow">{child.title}</Text>
                <ExternalArrow />
              </StyledAnchor>
            ) : (
              <StyledLink
                key={child.title}
                className={child._key === activeItem?._key ? "active" : ""}
                onClick={() => onInternalLinkClicked(child)}
                to={getLinkUrl(child)}
                reloadDocument={child.externalLink !== undefined}
              >
                <Text typography="mobileE2Eyebrow">{child.title}</Text>
              </StyledLink>
            ),
          )}
        </InnerContentWrapper>
      </OuterContentWrapper>
    </GroupWrapper>
  );
};

export { DrawerItemGroup };
