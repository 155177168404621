interface NumberFormatOptions {
  compactDisplay?: "short" | "long";
  notation?: "standard" | "scientific" | "engineering" | "compact";
  signDisplay?: "auto" | "never" | "always" | "exceptZero";
  unit?: string;
  unitDisplay?: "short" | "long" | "narrow";
  currencyDisplay?: "symbol" | "code" | "name";
  currencySign?: "standard" | "accounting";
  localeMatcher?: "lookup" | "best fit";
  style?: "decimal" | "percent" | "currency";
  currency?: string;
  useGrouping?: boolean;
  minimumIntegerDigits?: number;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
  minimumSignificantDigits?: number;
  maximumSignificantDigits?: number;
}

const roundDownToNearestHundred = (number: number) => {
  return Math.floor(number / 100) * 100;
};

const convertToCurrencyFormat = (value: number, options: NumberFormatOptions = {}, roundDown = false) => {
  const formattedValue = roundDown ? roundDownToNearestHundred(value) : value;

  return new Intl.NumberFormat("en-US", {
    ...options,
  }).format(formattedValue);
};

export { convertToCurrencyFormat };
