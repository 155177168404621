const infoTooltip = 1;
const carousel = 100;
const header = 100;
const liveButton = 110;
const tooltip = 200;
const drawer = 300;
const navbar = 400;
const blurredBackground = 500;

// live chat variables
const chatMessages = 0;
const liveChatTrigger = 4;
const liveChatToggleIcon = 5;
const liveChatEmojiPicker = 6;

const zIndex = {
  blurredBackground,
  carousel,
  drawer,
  header,
  infoTooltip,
  liveButton,
  liveChatToggleIcon,
  liveChatTrigger,
  liveChatEmojiPicker,
  navbar,
  tooltip,
  chatMessages,
};

export { zIndex };
