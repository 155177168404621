function GroupArrow() {
  return (
    <svg width="11" height="9" viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.08798 7.9007L0.538494 1.28326C0.310415 0.951514 0.547926 0.5 0.950515 0.5L10.0495 0.5C10.4521 0.5 10.6896 0.951513 10.4615 1.28326L5.91202 7.9007C5.71335 8.18968 5.28665 8.18968 5.08798 7.9007Z"
        fill="#BCB6BA"
      />
    </svg>
  );
}

export { GroupArrow };
