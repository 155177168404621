import { Button } from "@blastorg/portal-pattern-library";
import { useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { generateImageUrl } from "../../../../../api/data/assets/helpers/generateImageUrl";
import { generateAssetsId } from "../../../../../api/data/assets/helpers/generateAssetsId";
import { useAuthModal } from "../../../../../auth/AuthModalProvider";
import { useUserProfile } from "../../../../../auth/useUserProfile";
import { Text } from "../../../../../designSystemLegacy/typography/Text";
import { useUserWallet } from "../../../../hooks/useUserWallet";
import { Icon, IconButton, IconWrapperButton } from "../../../Icon";
import { Popup } from "../Popup";
import { Avatar, AvatarContainer, Container, CtaContainer, LoginButton } from "./styled";

import classNames from "classnames";
import { convertToCurrencyFormat } from "../../../../helpers/convertToCurrencyFormat";

interface ProfileProps {
  isTablet?: boolean;
  isDrawerExpanded?: boolean;
  isAuthenticated?: boolean;
  onOpenModal?: () => void;
}

const Profile = ({ isTablet, isDrawerExpanded, isAuthenticated, onOpenModal = () => undefined }: ProfileProps) => {
  const [popupOpened, setPopupOpened] = useState(false);
  const authModal = useAuthModal();
  const userProfile = useUserProfile();
  const { data: wallet, isLoading: isWalletLoading } = useUserWallet();

  const togglePopup = useCallback(() => {
    setPopupOpened((current) => !current);
  }, []);

  const onAvatarClick = useCallback(() => {
    if (!isTablet) {
      togglePopup();
    } else {
      if (isAuthenticated) {
        window.location.href = "/profile";
      } else {
        if (authModal?.isModalOpen) {
          authModal?.closeModal();
        } else {
          authModal?.openModal("register");
        }
      }
    }
  }, [authModal, isAuthenticated, isTablet, togglePopup]);

  const smallAvatarSrc = useMemo(() => {
    if (!userProfile?.avatarId) return undefined;
    return generateImageUrl("avatars", generateAssetsId(userProfile.avatarId, "3d"), {
      format: "auto",
      height: "200",
    });
  }, [userProfile]);

  return (
    <Container>
      {!isDrawerExpanded || isAuthenticated ? (
        <div className="flex items-center gap-3">
          {isWalletLoading ? (
            <div className="hidden h-7 place-content-center items-center rounded-full bg-canvas-90 md:flex">
              <div className="flex h-7 animate-pulse justify-between gap-2 rounded bg-canvas-100/50 p-1.5">
                <Icon icon="blastCoin" className="text-yellow" />
                <div className=" h-4 w-16 rounded-small bg-canvas-90" />
              </div>
            </div>
          ) : (
            wallet && (
              <Link
                to="/profile/redeem"
                className={classNames("[all:unset]", {
                  "hidden md:block": !isDrawerExpanded,
                })}
              >
                <div className="flex cursor-pointer items-center gap-2 rounded-full bg-canvas-90 px-2 py-1 hover:bg-canvas-80">
                  <Icon icon="blastCoin" className="text-yellow" />
                  <span className="font-style-label-2">{convertToCurrencyFormat(wallet.amount)}</span>
                </div>
              </Link>
            )
          )}

          <AvatarContainer>
            {smallAvatarSrc ? (
              <IconWrapperButton onClick={onAvatarClick} label="Profile">
                <Avatar src={smallAvatarSrc} className="scale-125" id={userProfile?.avatarId} />
              </IconWrapperButton>
            ) : (
              <IconButton icon="anonUser" label="Register" onClick={onAvatarClick} />
            )}
          </AvatarContainer>
        </div>
      ) : (
        <CtaContainer>
          <LoginButton
            onClick={() => {
              if (authModal?.openModal) {
                authModal?.openModal("login");
                onOpenModal();
              }
            }}
          >
            <Text typography="mobileE2Eyebrow">Log in</Text>
          </LoginButton>
          <Button
            onClick={() => {
              if (authModal?.openModal) {
                authModal?.openModal("register");
                if (onOpenModal) onOpenModal();
              }
            }}
          >
            Join the community
          </Button>
        </CtaContainer>
      )}
      {popupOpened && <Popup onClose={togglePopup} />}
    </Container>
  );
};

export { Profile };
