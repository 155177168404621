import { ExpandableNavbarItem } from ".";

export function getLinkUrl(navbarItem: ExpandableNavbarItem): string {
  if (navbarItem.externalLink) {
    return navbarItem.externalLink.startsWith("/")
      ? navbarItem.externalLink
      : new URL(navbarItem.externalLink).pathname;
  } else {
    return navbarItem.route ?? "#";
  }
}
