function throwIfUndefined(value: string | undefined, variableName: string): string {
  if (value === undefined) {
    throw new Error(`Required environment variable '${variableName}' was not set.`);
  }
  return value;
}

export const environment = throwIfUndefined(import.meta.env.VITE_ENVIRONMENT, "VITE_ENVIRONMENT") as
  | "production"
  | "development"
  | "local"
  | undefined;

export const livestateBaseUrl = throwIfUndefined(import.meta.env.VITE_LIVE_STATE_BASE_URL, "VITE_LIVE_STATE_BASE_URL");
export const baseAssetsURL = throwIfUndefined(import.meta.env.VITE_ASSETS_BASE_URL, "VITE_ASSETS_BASE_URL");
export const apiBaseURL = throwIfUndefined(import.meta.env.VITE_API_BASE_URL, "VITE_API_BASE_URL");
export const rudderstackWriteKey = throwIfUndefined(import.meta.env.VITE_RUDDERSTACK_KEY, "VITE_RUDDERSTACK_KEY");
export const dataBucketBaseURL = throwIfUndefined(
  import.meta.env.VITE_DATA_BUCKET_BASE_URL,
  "VITE_DATA_BUCKET_BASE_URL",
);

export const clipsAwsBaseURL = throwIfUndefined(import.meta.env.VITE_CLIPS_AWS_BASE_URL, "VITE_CLIPS_AWS_BASE_URL");

export const sanityDataset = throwIfUndefined(import.meta.env.VITE_SANITY_DATASET, "VITE_SANITY_DATASET");
export const flagsmithEnvironmentId = throwIfUndefined(
  import.meta.env.VITE_FLAGSMITH_ENVIRONMENT_ID,
  "VITE_FLAGSMITH_ENVIRONMENT_ID",
);

export const signupURL = "/sign-up";

export const triggersResultsDelaySeconds = 30; // seconds
export const takeoverResultsDelayFurtherSeconds = 30; // seconds

export const liveDropRedeemIntervalSecondsMin = 1500; // seconds
export const liveDropRedeemIntervalSecondsMax = 2700; // seconds
