import { Bullet } from "./styled";
import { TeeVeeState } from "../TeeVeeAnimation";
import { AuthModalState } from "../../../../../auth/AuthModalProvider";

export const getTeeVeeState = (state: AuthModalState): TeeVeeState | undefined => {
  switch (state) {
    case "register":
      return "roll-in";

    case "awaiting-verification-email":
      return "verify";

    default:
      return undefined;
  }
};

export const getHeaderText = (state: AuthModalState): string => {
  switch (state) {
    case "login":
      return "Log in to your account";
    case "first-login":
      return "Thanks for signing up! Please log in to complete your profile!";
    case "register":
      return "Join the community";
    case "forgot-password":
    case "reset-password-requested":
      return "Forgot your password?";
    case "awaiting-verification-email":
    case "verification-email-resent":
      return "Verify your email";
    case "ban":
      return "THIS ACCOUNT IS PERMANENTLY BANNED.";
    default:
      return "";
  }
};

export const getSubmitButtonText = (state: AuthModalState): string => {
  switch (state) {
    case "login":
    case "first-login":
      return "Log in";
    case "register":
      return "Join the community";
    case "forgot-password":
      return "Send Link";
    case "ban":
      return "Okay";
    default:
      return "";
  }
};

export const getDescriptionLinkText = (state: AuthModalState): string => {
  switch (state) {
    case "login":
      return "Join the community";

    case "register":
      return "Log in";

    case "reset-password-requested":
      return "Resend";

    case "awaiting-verification-email":
      return "Resend";

    default:
      return "";
  }
};

export const getDescriptionText = (state: AuthModalState): string => {
  switch (state) {
    case "login":
      return "Don't have an account yet?";

    case "register":
      return "Already a member?";

    case "reset-password-requested":
    case "awaiting-verification-email":
      return "Didn't receive an email and checked your spam?";

    case "verification-email-resent":
      return "We've sent you an email with the link again.";

    case "ban":
      return "Your user has been permanently banned due to violation of our Terms and Conditions/Community Guidelines. You are no longer able to use your account with Blast.tv.";

    default:
      return "";
  }
};

export const getCopyTextSpan = (state: AuthModalState): JSX.Element | null => {
  switch (state) {
    case "register":
      return (
        <span>
          {
            "The ultimate destination for everything Dota and CS! Whether you're a die-hard fan or a casual observer, enjoy:"
          }
          <br />
          <br />
          <Bullet>&#8226;</Bullet>
          {"A fun and inclusive community led space"}
          <br />
          <Bullet>&#8226;</Bullet>
          {"Exclusive polls, Q&As and an esport specific newsletter"}
          <br />
          <Bullet>&#8226;</Bullet>
          {"Live stream chats with other fans and talent"}
          <br />
          <br />
          {"and many more feature to discover!"}
        </span>
      );

    case "forgot-password":
      return (
        <span>
          {`Don't worry! Even the best of us forget. Get a link to reset your
            password by entering the email address you used when registering on
            BLAST.tv.`}
        </span>
      );

    case "reset-password-requested":
      return (
        <span>
          {`Thanks! If the email address is registered on BLAST.tv, you'll receive an email with a link to reset your password shortly.`}
        </span>
      );

    case "awaiting-verification-email":
      return (
        <span>
          {`We've sent a verification email to you. You know the drill - click the link in the email to verify your account and start enjoying BLAST.tv asap!`}
        </span>
      );

    case "verification-email-resent":
      return (
        <span>
          {`We've sent a verification email to you. You know the drill - click the link in the email to verify your account and start enjoying BLAST.tv asap!`}
        </span>
      );

    case "ban":
      return <span>If you have questions, contact us at support@blast.tv</span>;

    default:
      return null;
  }
};
