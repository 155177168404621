import { ReactNode, createContext } from "react";
import { useGetFlag } from "../../api/data/flagsmith/getFlags/useGetFlag";
import { useGameIdFromLocation } from "./useGameIdFromLocation";
import { z } from "zod";

export const GameIdSchema = z.enum(["cs", "rl", "dota"]);
export type GameId = z.infer<typeof GameIdSchema>;
const allGameIds: GameId[] = ["cs", "dota", "rl"];

type GameIdContextValue = {
  gameId: GameId | null; // current game id, null means global context
  enabledGames: GameId[]; // game ids that are enabled via feature flags
  allGameIds: GameId[]; // all supported game ids
};

export const GameIdContext = createContext<GameIdContextValue>({
  gameId: null,
  allGameIds,
  enabledGames: ["cs", "dota"],
});

export const GameIdProvider = ({ children }: { children: ReactNode }) => {
  const { enabled: isRocketLeagueEnabled } = useGetFlag("rocket_league");

  const enabledGames: GameId[] = ["cs", "dota"];

  if (isRocketLeagueEnabled) {
    enabledGames.push("rl");
  }

  const gameIdFromLocation = useGameIdFromLocation({ enabledGames });
  const gameId = enabledGames.length === 1 ? enabledGames[0] : gameIdFromLocation;

  return <GameIdContext.Provider value={{ allGameIds, gameId, enabledGames }}>{children}</GameIdContext.Provider>;
};
