import { Navigate, Outlet } from "react-router-dom";
import { useGetFlag } from "@data/flagsmith/getFlags/useGetFlag";
import { LoadingPage } from "./LoadingPage";

export const FlaggedRoute = ({ flag }: { flag: string }) => {
  const { enabled: isFlagEnabled, loading: isFlagLoading } = useGetFlag(flag ?? "");

  if (isFlagLoading) {
    return <LoadingPage />;
  }

  if (!isFlagEnabled) {
    return <Navigate to="/" replace />;
  }

  return <Outlet />;
};
