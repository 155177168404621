import { useLocation } from "react-router-dom";
import { GameId } from ".";

export const useGameIdFromLocation = ({ enabledGames }: { enabledGames: GameId[] }) => {
  const location = useLocation();

  const globalPages = ["/", "/profile", "/privacy-policy"];

  if (globalPages.includes(location.pathname)) {
    return null; // null means no game - global context
  }
  const gameSegment = location.pathname.toLowerCase().split("/")[1];
  if (enabledGames.includes(gameSegment as GameId)) {
    return gameSegment as GameId;
  }

  // default to cs for all paths that aren't root or /rl
  // we need to slowly migrate CS content to /cs
  return "cs";
};
