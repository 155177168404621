import classNames from "classnames";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { NavbarItem as NavbarItemType } from "../../../../../api/data/sanity/getSettings/schemas/NavbarSchema";
import { useAuthModal } from "../../../../../auth/AuthModalProvider";
import { useAuth } from "../../../../../auth/AuthProvider/hooks/useAuth";
import { Text } from "../../../../../designSystemLegacy/typography/Text";
import { useFetchLiveBroadcasts } from "../../../../../api/data/broadcasts/useFetchLiveBroadcasts";
import { useTheaterMode } from "../../../../hooks/useTheaterMode";
import { useAlert } from "../../../../../providers/AlertProvider/hooks/useAlert";
import { rudderstack } from "../../../../../rudderstack";
import { useGetNavbarBroadcastSeries } from "../../hooks/useGetNavbarBroadcastSeries";
import { useNavBarContext } from "../../useNavBarContext";
import { NavbarItem } from "../NavbarItem";
import { NavbarLogoItem } from "../shared/NavbarLogoItem";
import { Profile } from "../Profile";
import { LoginButton, LoginText, Logo, NavContent, RegisterButton } from "./styled";
import { GameNav } from "./components/GameNav";
import { useGameId } from "@providers/GameIdProvider/useGameId";
import { DotaSearchButton } from "../DotaSearchButton";

const isItemActive = (item: NavbarItemType, pathname: string) => {
  const isRouteActive = (route: string | undefined | null): boolean => {
    return !!route && route === pathname;
  };

  if (item.children) {
    return item.children.some((child) => isRouteActive(child.route));
  } else {
    return isRouteActive(item.route);
  }
};

export const NavBarDesktop = ({
  nav,
  isAutomaticLiveEnabled = false,
}: {
  nav?: { cs: NavbarItemType[]; dota: NavbarItemType[]; rl: NavbarItemType[] };
  isAutomaticLiveEnabled?: boolean;
}) => {
  const { enabledGames: enabledGameIds, gameId } = useGameId();

  const { isNavTransparent, setIsNavTransparent, showDotaSearchButton } = useNavBarContext();
  const { isAuthenticated } = useAuth();

  const navigation = useLocation();

  useEffect(() => {
    if (isNavTransparent) {
      setIsNavTransparent(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigation.pathname]);

  const handleScroll = () => {
    const position = window.scrollY;
    const pos = (24 / 100) * position;
    const percentage = Math.min(pos, 1);

    // Set the opacity of the navbar using javascript
    const navbar = document.getElementById("transparent-navbar");

    if (!navbar) return;

    navbar.style.backgroundColor = `rgb(31 12 25 / ${percentage})`;
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const { showInfoAlert } = useAlert();
  const onLiveBroadcastStart = useCallback(() => {
    showInfoAlert("Live stream just started!");
  }, [showInfoAlert]);
  const { data: liveBroadcasts } = useFetchLiveBroadcasts({
    onLiveBroadcastStart,
    enabled: isAutomaticLiveEnabled,
  });
  const { openModal } = useAuthModal();
  const { pathname } = useLocation();
  const { isTheaterMode } = useTheaterMode();

  const broadcastSeries = useGetNavbarBroadcastSeries({ liveBroadcasts });

  const navbarItems = useMemo(() => {
    if (gameId === "cs") return nav?.cs || [];
    if (gameId === "dota") return nav?.dota || [];
    if (gameId === "rl") return nav?.rl || [];
    return []; // global home page has custom game nav
  }, [gameId, nav]);

  const activeNavbarItemKey = useMemo(() => {
    const activeItem = navbarItems.find((item) => isItemActive(item, pathname));
    return activeItem?._key;
  }, [navbarItems, pathname]);

  const navbarLiveChildren: NavbarItemType[] = useMemo(() => {
    if (!broadcastSeries || !isAutomaticLiveEnabled) return [];

    return Object.values(broadcastSeries).map((broadcast) => ({
      _type: "navbarItem",
      _key: broadcast.id,
      title: broadcast.title,
      route: `/live/${broadcast.slug}`,
    }));
  }, [broadcastSeries, isAutomaticLiveEnabled]);

  const [navItemsVisible, setNavItemsVisible] = useState(true);

  return (
    <nav
      id={isNavTransparent ? "transparent-navbar" : undefined}
      className={classNames("fixed top-0 z-navbar w-screen select-none transition duration-300 ease-in-out", {
        "h-0 overflow-hidden": isTheaterMode,
        "h-[60px]": !isTheaterMode,
        "!bg-canvas-100": !isNavTransparent,
      })}
    >
      <NavContent>
        <div className="flex h-full grow gap-6">
          <NavbarLogoItem className="h-full">
            <Logo isActive={pathname === "/"} />
          </NavbarLogoItem>

          {enabledGameIds.length > 1 && (
            <GameNav onCollapsed={() => setNavItemsVisible(true)} onExpanded={() => setNavItemsVisible(false)} />
          )}

          {gameId === "cs" && navbarLiveChildren?.length > 0 && (
            <div
              className={classNames("transition-opacity delay-200 duration-300", {
                "opacity-0": !navItemsVisible,
                "opacity-100": navItemsVisible,
              })}
            >
              <NavbarItem
                isLiveOption={true}
                item={{
                  _type: "navbarItem",
                  _key: "live",
                  title: "Live",
                  route: "",
                  children: navbarLiveChildren,
                }}
                isActive={pathname.startsWith("/live")}
              />
            </div>
          )}

          {navbarItems.map((item) => (
            <div
              key={item._key}
              className={classNames("transition-opacity delay-200 duration-300", {
                "opacity-0": !navItemsVisible,
                "opacity-100": navItemsVisible,
              })}
            >
              <NavbarItem item={item} isActive={activeNavbarItemKey === item._key} />
            </div>
          ))}
        </div>
        {showDotaSearchButton && <DotaSearchButton className="mr-3" />}
        {!isAuthenticated && (
          <LoginButton
            onClick={() => {
              rudderstack?.track("Login Button Clicked", {
                context: "top-navigation",
              });
              return openModal("login");
            }}
          >
            <LoginText variant="b3BodyCopy">Log in</LoginText>
          </LoginButton>
        )}
        {!isAuthenticated ? (
          <RegisterButton onClick={() => openModal("register")}>
            <Text typography="mobileE2Eyebrow">Join the community</Text>
          </RegisterButton>
        ) : (
          <Profile isAuthenticated={isAuthenticated} />
        )}
      </NavContent>
    </nav>
  );
};
