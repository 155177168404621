import styled, { css } from "styled-components";
import logo from "./assets/logo.gif";
import { toRem } from "@shared/helpers/toRem";
import { Link } from "react-router-dom";
import { colors } from "../../../../../../../designSystemLegacy/colors";
import { NavbarLogoItem } from "../../../shared/NavbarLogoItem";

export const DrawerItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: ${toRem(40)};
  justify-content: left;
  overflow-y: auto;

  > *:last-child {
    margin-bottom: ${toRem(128)};
  }
`;

export const DrawerLogo = styled.div<{ isActive: boolean }>`
  width: ${toRem(30)};
  height: ${toRem(36)};
  background: url("${logo}") no-repeat center;
  background-size: contain;
`;

const drawerItemMixin = css<{ $isActive?: boolean }>`
  border-bottom: 2px solid transparent;
  transition: all 0.2s ease-in-out;
  padding-bottom: ${toRem(8)};
  width: fit-content;
  cursor: pointer;

  ${(props) => props.$isActive && `border-bottom: 2px solid ${colors.blastYellow};`}
`;

export const StyledNavbarLogoItem = styled(NavbarLogoItem)<{
  isActive: boolean;
}>`
  ${drawerItemMixin}
`;

export const StyledLink = styled(Link)`
  ${drawerItemMixin}
  color: ${colors.white};
  text-decoration: none;
  display: flex;
  align-items: center;
  text-align: center;
`;

export const StyledAnchor = styled.a`
  ${drawerItemMixin}
  color: ${colors.white};
  text-decoration: none;
  display: flex;
  align-items: center;
  text-align: center;
  gap: ${toRem(4)};

  > svg {
    width: ${toRem(10)};
    height: ${toRem(12)};
  }
`;
